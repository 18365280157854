/**
 * auther pengliangwei
 * version 1.9
 * uptime 2020-06-23
 */
import Kernel from './kernel';

console.log('version: 1.7');

const zcqSDK = new Kernel();
zcqSDK.init();

const apiList = {
  checkJsApi: 'onZcqCheckJsApi',
  baseInfo: 'onZcqBaseInfo',
  phoneCall: 'onZcqCallAction',
  sendPhoneMsg: 'onZcqSendMsgAction',
  chooseImage: 'onZcqPickImageAction',
  audioAction: 'onZcqAudioAction',
  audioPlayPause: 'onZcqAudioPlayPauseAction',
  videoAction: 'onZcqVideoAction',
  locationAction: 'onZcqLocationAction',
  inputAction: 'onZcqInputAction',
  copyAction: 'onZcqCopyAction',
  goAppPayRouter: 'onZcqPayView',
  backAction: 'onZcqBackAction',
  goBack: 'onZcqGoBackAction',
  closeView: 'onZcqCloseViewAction',
  trackAction: 'onZcqTrackAction',
  hideTitleBar: 'onZcqHideTitleBarAction',
  controlShare: 'onZcqControlShareAction',
  controlRefresh: 'onZcqControlRefreshAction',
  controlOther: 'onZcqControlOtherAction',
  titleBarStyle: 'onZcqTitleBarStyleAction',
  setTitleBarName: 'onZcqTitleBarNameAction',
  showShareControl: 'onZcqShowShareControlAction',
  shareContent: 'onZcqShareContentAction',
  goAppViewRouter: 'onZcqGoAppViewRouter',
  goAppImRouter: 'onZcqIMAction',
  jobDetailWithShare: 'onZcqJobDetailWithShareAction',
  recordingAudio: 'onZcqRecordingAudio',
  recordingVideo: 'onZcqRecordingVideo',
  saveImgToAlbum: 'onZcqSaveImgToAlbum',
  shareMiniProgram: 'onZcqShareMiniProgramAction',
  getDocumentHeightForIos: 'onZcqGetDocumentHeightForIos',
  goAppSobot: 'onZcqGoAppSobot',
  goAppLoginPage: 'onGuideLogin',
  getGeolocation: 'onZcqGeolocation',
  checkNetwork: 'onZcqCheckNetwork',
  checkPush: 'onZcqCheckPush',
  toSystemSetting: 'onZcqToSystemSettingAction',
  informAppVipBuySuccess: 'onZcqInformAppVipBuySuccess',
  inOrBackWebView: 'onZcqInOrBackWebView',
  forbidSideslip: 'onZcqForbidSideslip',
  jumpNewWebView: 'onZcqJumpNewWebView',
};

function ready(callback) {
  zcqSDK.ready(callback);
}

// 参数解释
// actionName 和app约定的方法名
// paramsType 必传参数和数据类型校验

/**
 * 校验当前客户端版本是否支持js-sdk接口
 * @param params
 */
function checkJsApi(params) {
  const actionName = apiList.checkJsApi;
  const paramsType = {};
  if (params.jsApiList && Array.isArray(params.jsApiList) && params.jsApiList.length) {
    const list = [];
    const errList = [];
    params.jsApiList.forEach((e) => {
      const api = apiList[e];
      if (api) {
        list.push({ [api]: e });
      } else {
        errList.push(e);
      }
    });
    if (errList.length) {
      console.error(`校验参数错误:没有这些api${errList},请参考js-sdk说明文档：http://blue.51zouchuqu.com/doc/detail.html?id=af710f70-adbc-11e9-a0e7-bd940c87ceb5&queryType=all`);
    }
    const newParams = { ...params, jsApiList: list };
    zcqSDK.kernelWay(actionName, newParams, paramsType);
  } else {
    console.error(`参数错误cerror: jsApiList应该是非空数组，传入内容为：${params}`);
  }
}

/**
 * 获取当前客户端版本基础信息
 * @param params
 */
function baseInfo(params) {
  const actionName = apiList.baseInfo;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 打电话
 * @param params
 */
function phoneCall(params) {
  const actionName = apiList.phoneCall;
  const paramsType = { phoneNum: 'string' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 发短信
 * @param params
 */
function sendPhoneMsg(params) {
  const actionName = apiList.sendPhoneMsg;
  const paramsType = { msg: 'string', phoneNumber: 'string' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}


/**
 * 选择图片
 * @param params
 */
function chooseImage(params) {
  const actionName = apiList.chooseImage;
  const paramsType = { num: 'number' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 初始化音频/或直接播放
 * @param params
 */
function audioAction(params) {
  const actionName = apiList.audioAction;
  const paramsType = { url: 'string', auto: 'boolean' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 控制音频播放暂停
 * @param params
 */
function audioPlayPause(params) {
  const actionName = apiList.audioPlayPause;
  const paramsType = { cation: 'boolean' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 初始化视频/或直接播放
 * @param params
 */
function videoAction(params) {
  const actionName = apiList.videoAction;
  const paramsType = { url: 'string', auto: 'boolean' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 获取城市名、经纬度
 * @param params
 */
function locationAction(params) {
  const actionName = apiList.locationAction;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 获原生提供底部输入框
 * @param params
 */
function inputAction(params) {
  const actionName = apiList.inputAction;
  const paramsType = { isShow: 'boolean', length: 'number' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 黏贴板
 * @param params
 */
function copyAction(params) {
  const actionName = apiList.copyAction;
  const paramsType = { msg: 'string' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 支付
 * @param params
 */
function goAppPayRouter(params) {
  const actionName = apiList.goAppPayRouter;
  const paramsType = { orderInfo: 'string', price: 'string', mOrderPayId: 'string' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 阻止返回上一级页面
 * @param params
 */
function backAction(params) {
  const actionName = apiList.backAction;
  const paramsType = { isMonitor: 'boolean' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 返回上一级页
 * @param params
 */
function goBack(params) {
  const actionName = apiList.goBack;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 关闭当前webview
 * @param params
 */
function closeView(params) {
  const actionName = apiList.closeView;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 关闭时向神策发送数据
 * @param params
 */
function trackAction(params) {
  const actionName = apiList.trackAction;
  const paramsType = { trackName: 'string', content: 'object' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 隐藏 header 标题栏
 * @param params
 */
function hideTitleBar(params) {
  params = {type:1, ...params}
  const actionName = apiList.hideTitleBar;
  const paramsType = {type: 'number'};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 控制是否显示分享
 * @param params
 */
function controlShare(params) {
  const actionName = apiList.controlShare;
  const paramsType = { isShow: 'boolean' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 控制是否显示刷新
 * @param params
 */
function controlRefresh(params) {
  const actionName = apiList.controlRefresh;
  const paramsType = { isShow: 'boolean' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 标题栏的背景色、字体颜色
 * @param params
 */
function titleBarStyle(params) {
  const actionName = apiList.titleBarStyle;
  const paramsType = { background: 'string', color: 'string' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 展其他
 * @param params
 */
function controlOther(params) {
  const actionName = apiList.controlOther;
  const paramsType = { type: 'number', titleName: 'string', content: 'object' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 设置标题
 * @param params
 */
function setTitleBarName(params) {
  const actionName = apiList.setTitleBarName;
  const paramsType = { msg: 'string' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 吊起分享模态框
 * @param params
 */
function showShareControl(params) {
  const actionName = apiList.showShareControl;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 设置分享内容
 * @param params
 */
function shareContent(params) {
  const actionName = apiList.shareContent;
  const paramsType = { shareType: 'number', type: 'array', content: 'object' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 反射
 * @param params
 */
function goAppViewRouter(params) {
  const actionName = apiList.goAppViewRouter;
  const paramsType = { className: 'object', parameters: 'object' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 转到聊天页面
 * @param params
 */
function goAppImRouter(params) {
  params = {type: 0, ...params}
  const actionName = apiList.goAppImRouter;
  const paramsType = {type:'number', userID: 'string' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 转到聊天页面
 * @param params
 */
function jobDetailWithShare(params) {
  const actionName = apiList.jobDetailWithShare;
  const paramsType = { jobId: 'string', content: 'object' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 录音
 * @param params
 */
function recordingAudio(params) {
  const actionName = apiList.recordingAudio;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 录视频
 * @param params
 */
function recordingVideo(params) {
  const actionName = apiList.recordingVideo;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 保存图片到相册
 * @param params
 */
function saveImgToAlbum(params) {
  const actionName = apiList.saveImgToAlbum;
  const paramsType = { type: 'string', data: 'string' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 设置微信小程序，包含h5
 * @param params
 */
function shareMiniProgram(params) {
  const actionName = apiList.shareMiniProgram;
  const paramsType = { shareType: 'number', type: 'array', content: 'object' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * ios 获取到当前页面高度，给到ios使ios的webview变化
 * @param params
 */
function getDocumentHeightForIos(params) {
  const actionName = apiList.getDocumentHeightForIos;
  const paramsType = { height: 'number' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 跳转到智齿
 * @param params
 */
function goAppSobot(params) {
  const actionName = apiList.goAppSobot;
  const paramsType = { type: 'string' };
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 吊起ios的登录弹窗，ios有游客模式。所以存在无token的情况，安卓无游客模式。已做ios和安卓的兼容
 * @param params
 */
function goAppLoginPage(params) {
  const actionName = apiList.goAppLoginPage;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 获取原生选择的当前定位
 * @param params
 */
function getGeolocation(params) {
  const actionName = apiList.getGeolocation;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 检查网络环境
 */
function checkNetwork(params) {
  const actionName = apiList.checkNetwork;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 检查是否开启推送
 */
function checkPush(params) {
  const actionName = apiList.checkPush;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 调到App系统设置
 * @param params
 */
function toSystemSetting(params) {
  const actionName = apiList.toSystemSetting;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * b端c端购买会员成功后需要通知app进行页面的状态刷新。
 * @param params
 */
function informAppVipBuySuccess(params) {
  const actionName = apiList.informAppVipBuySuccess;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}


/**
 * IOS禁止侧滑出webview
 * @param params
 */
function forbidSideslip(params) {
  const actionName = apiList.forbidSideslip;
  const paramsType = {};
  zcqSDK.kernelWay(actionName, params, paramsType);
}

/**
 * 新开一个webview
 * @param params
 */
function jumpNewWebView(params) {
  const actionName = apiList.jumpNewWebView;
  const paramsType = {url:'string'};
  zcqSDK.kernelWay(actionName, params, paramsType);
}



/**
 * 移动端新进入或者退回webView调用方法
 * @param params
 */
function inOrBackWebView(params={}) {
  const actionName = apiList.inOrBackWebView;
  params.type = actionName
  zcqSDK.nativeCallWebJsKernelWay(params);
}


const apis = {
  ready,
  shareMiniProgram,
  informAppVipBuySuccess,
  getGeolocation,
  getDocumentHeightForIos,
  goAppSobot,
  checkJsApi,
  baseInfo,
  phoneCall,
  sendPhoneMsg,
  chooseImage,
  audioAction,
  audioPlayPause,
  videoAction,
  locationAction,
  inputAction,
  copyAction,
  goAppPayRouter,
  backAction,
  goBack,
  closeView,
  trackAction,
  hideTitleBar,
  controlShare,
  controlRefresh,
  controlOther,
  titleBarStyle,
  setTitleBarName,
  showShareControl,
  shareContent,
  goAppViewRouter,
  goAppImRouter,
  jobDetailWithShare,
  recordingAudio,
  recordingVideo,
  saveImgToAlbum,
  goAppLoginPage,
  checkNetwork,
  checkPush,
  toSystemSetting,
  inOrBackWebView,
  forbidSideslip,
  jumpNewWebView
};

export default apis