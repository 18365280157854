import { getCookie } from './utils';

export default class Connect {
  constructor() {
    this.env = undefined;
    this.Bridge = null;
  }

  async init() {
    this.judgeClient(); // 检测客户端
    if (this.env) { // 只有在app内部才进行初始化
      this.Bridge = await this.initBridge();
      return { env: this.env, Bridge: this.Bridge };
    }
    console.error('bridge初始化失败，不在app内部');
    return false;
  }

  /**
   * 初始化客户端桥
   *
   */
  initBridge() {
    return new Promise(((resolve) => {
      if (this.env === 1) {
        resolve(window.android);
      } else if (this.env === 2) {
        this.setupWebViewJavascriptBridge((bridge) => {
          resolve(bridge);
        });
      }
    }));
  }

  /**
   * IOS 初始化桥
   * @param 回调函数
   */
  setupWebViewJavascriptBridge(callback) { // wkWebView --TODO
    if (window.WebViewJavascriptBridge) {
      return callback(window.WebViewJavascriptBridge);
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback);
    }
    window.WVJBCallbacks = [callback];
    const WVJBIframe = document.createElement('iframe');
    WVJBIframe.style.display = 'none';
    WVJBIframe.src = 'https://__bridge_loaded__';
    document.documentElement.appendChild(WVJBIframe);
    setTimeout(() => {
      document.documentElement.removeChild(WVJBIframe);
    }, 0);
  }

  /**
   * 检测客户端，检测是否在我们的app内部
   *
   */
  judgeClient() {
    console.log('zcqAppVersion', document.cookie);
    const u = window.navigator.userAgent;
    const cookie = getCookie();
    console.log('userAgent', u);
    if (u.toLowerCase().indexOf('zcq-app') === -1 && !cookie.zcqAppVersion) {
      return;
    }
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // 判断是否是 android终端
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 iOS终端
    if (isAndroid) {
      this.env = 1; // 'Android'
    } else if (isIOS) {
      this.env = 2; // 'IOS'
    } else {
      this.env = undefined; // '不在app内部'
    }
  }
}
