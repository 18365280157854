import Connect from './connect';
import { checkParamsType, defaultParams } from './utils';
import { AndroidCompatible, IOSCompatible } from './compatible';


export default class Kernel {
  constructor() {
    this.env = undefined;
    this.Bridge = null;
    this.readyCallback = [];
    this.isReady = false;
  }

  /**
   * 初始化，获得Bridge和env
   */
  async init() {
    const that = this;
    const ConnectInfo = new Connect();
    const connect = await ConnectInfo.init();
    if (connect) {
      that.env = connect.env;
      that.Bridge = connect.Bridge;
      this.isReady = true;
      if (this.readyCallback.length) {
        this.readyCallback.forEach(e => e());
      }
    } else {
      this.isReady = true;
    }
  }

  /**
   * ready 安全调用,支持重复调用
   * 如果页面一打开就调用api，那么需要使用ready包裹做安全调用
   */
  ready(callback) {
    if (this.Bridge) {
      callback();
    } else {
      this.readyCallback.push(callback);
    }
  }

  /**
   * 对前端传入的参数进行校验，再校验通过后对参数进行拼接默认参数
   * @param
   * actionName 和原生约定的方法名
   * params 前端传入的参数
   * paramsType 必传参数类型对象
   */
  /* eslint no-param-reassign: "error" */
  checkParamsAndAssembleDefaultParams(actionName, params, paramsType) {
    if (!this.isReady) { // 当前状态还未初始化完成
      this.readyCallback.push(() => this.kernelWay(actionName, params, paramsType));
      return false;
    }
    if (!this.env) { // 运行环境检查
      return false;
    }
    if (!checkParamsType(params, paramsType)) return false; // 必传参数和类型检查
    return { ...defaultParams(), ...params, zcqCallBack: `${actionName}CallBackName` }; // 组装参数，ios和安卓不接受function
  }

  /**
   * 和原生进行交互
   * @param
   * actionName 和原生约定的方法名
   * params 传给原生的参数
   */
  interactionForNative(actionName, params) {
    console.log('debug', actionName, params);
    if (this.env === 2) { // 1安卓 2 ios
      const iosC = IOSCompatible(this.Bridge, actionName, params);
      if (iosC) {
        // 前端调用ios方法。
        this.Bridge.callHandler(actionName, params, response => params.success(response));
        // ios想要调用前端方法。也需要把方法注册到Bridge中。
        this.Bridge.registerHandler(params.zcqCallBack, (data) => {
          params.success(data);
        });
      }
    } else if (this.env === 1) {
      const andC = AndroidCompatible(this.Bridge, actionName, params); // 兼容检查
      if (andC) {
        if(this.Bridge[actionName]){ // 兼容老版本无这个方法时，调用不报错
          this.Bridge[actionName](JSON.stringify(params)); // 安卓只接受json字符串
          window[params.zcqCallBack] = (res) => {
            params.success(res);
          };
        }else{
          console.warn(`当前安卓版本无此方法:${actionName}`)
        }
      }
    } else {
      console.error(`${actionName} 没有在app内部调用`);
    }
  }

  /**
   * 原生直接调用web端方法，和interactionForNative方法的区别是web端不用调用移动端方法
   * @param {*} params.type 和移动端约定的方法
   * @param {*} params.success 移动端调用web端方法后，web端在调用自己的回调
   *
   */
  nativeCallWebJs(params){
    console.log('debug-nativeCallWebJs', params);
    if (this.env === 2) { // 1安卓 2 ios
      // console.log('ios Bridge', this.Bridge)
        // ios想要调用前端方法。也需要把方法注册到Bridge中。
        this.Bridge.registerHandler(params.type, (data) => {
          params.success(data);
        });
    } else if (this.env === 1) {
      window[params.type] = (res) => {
        params.success(res);
      };
    } else {
      console.error(`${params.type} 没有在app内部调用`);
    }
  }

  /**
   * 移动端直接调用web端方法入口
   * @param {*} actionName 和移动端约定的方法名
   */
  nativeCallWebJsKernelWay(action){
    const params = { ...defaultParams(), ...action }
    if (!this.isReady) { // 当前状态还未初始化完成
      this.readyCallback.push(() => this.nativeCallWebJs(params));
    }else{
      this.nativeCallWebJs(params)
    }
  }

  /**
   *核心方法入口
   * @param
   * actionName 和app约定的字段
   * params 前端传入的参数
   * paramsType 必传参数校验
   */
  kernelWay(actionName, params, paramsType) {
    const willSendParams = this.checkParamsAndAssembleDefaultParams(actionName, params, paramsType);
    if (!willSendParams) return;
    this.interactionForNative(actionName, willSendParams);
  }
}
