// 安卓和ios有的老方法不一致。新定义的sdk未覆盖到原来的老方法。所以各端需要做兼容

/**
 * 安卓兼容
 * @param params
 * par参数 sdk名
 *
 */
const AndroidCompatible = (Bridge, actionName, params) => {
  switch (actionName) {
    case 'onZcqPayView':
      Bridge[actionName](JSON.stringify(params));
      window.onPaySuccess = (res) => {
        params.success({ code: 200, data: { ...res } });
      };
      window[params.zcqCallBack] = () => { };
      break;
    case 'onGuideLogin':
      params.success({ code: 200, data: {} });
      break;
    case 'onZcqGeolocation':
      Bridge[actionName](params.zcqCallBack);
      window[params.zcqCallBack] = (res) => {
        params.success(res);
      };
      break;
    default: return true;
  }
};

/**
 * ios 兼容
 * @param params
 * par参数 sdk名
 *
 */
const IOSCompatible = (Bridge, actionName, params) => {
  switch (actionName) {
    case 'onZcqGeolocation':
      Bridge.callHandler(actionName, { ...params, method: params.zcqCallBack },
        response => params.success(response));
      // ios想要调用前端方法。也需要把方法注册到Bridge中。
      Bridge.registerHandler(params.zcqCallBack, (data) => {
        params.success(data);
      });
      break;
    default: return true;
  }
};


export {
  AndroidCompatible,
  IOSCompatible,
};
