// 检查参数类型
/* eslint valid-typeof: "error" */

/**
 * 校验参数类型
 * @param params
 * par参数
 * sType类型
 * 返回 true 或 false, false 表示参数校验不通过
 */
const checkParamsType = (params, paramsType) => {
  let resulet = true
  if(Object.prototype.toString.call(paramsType) === "[object Object]" ){
    Object.keys(paramsType).forEach((e) => {
      if(Object.prototype.toString.call(params) !== "[object Object]" ){ // 当校验参考为可用对象时，必须有传入的参数
        console.error(`error类型错误：需要传入的参数有 ${JSON.stringify(paramsType)}, 但是传入值为：${params}`);
        resulet = false;
      }else if (typeof paramsType[e] === 'object') {
        if (paramsType[e] === null) {
          if(params[e] !== null){
            console.error(`error类型错误：传入的参数值中${e}:${params[e]} 应该是${paramsType[e]} 类型`);
            resulet = false;
          }
        } else if (paramsType[e] === 'array') {
          if(!Array.isArray(params[e])){
            console.error(`error类型错误：传入的参数值中${e}:${params[e]} 应该是${paramsType[e]} 类型`);
            resulet = false;
          }
        }else{
          resulet = checkParamsType(params[e], paramsType[e])
        }
      } else if (paramsType[e] === 'array' && Array.isArray(params[e])) { // 处理数组特例
        resulet = true
      } else if (typeof params[e] !== paramsType[e]) {
        console.error(`error类型错误：传入的参数值中${e}:${params[e]} 应该是${paramsType[e]} 类型`);
        resulet = false;
      }
    });
  }else{
    if(`${paramsType}` !== 'undefined'){ // paramsType可能是是定义的类型，从对象内部来。也可能是值，即没有定义类型undefined
      if (typeof params !== paramsType) { 
        console.error(`error类型错误：传入的参数值中${params} 应该是${paramsType} 类型`);
        resulet = false;
      }
    }
  }
  return resulet
};

// 获取url中的参数
const getQueryString = (serch) => {
  const queryString = {};
  const query = serch || window.location.search.substr(1);
  if (!query) {
    return {};
  }
  const vars = query.split('&');
  vars.forEach((v) => {
    const pair = v.split('=');
    if (!Object.prototype.hasOwnProperty.call(queryString, pair[0])) {
      queryString[pair[0]] = decodeURIComponent(pair[1]);
    } else if (typeof queryString[pair[0]] === 'string') {
      const arr = [queryString[pair[0]], decodeURIComponent(pair[1])];
      queryString[pair[0]] = arr;
    } else {
      queryString[pair[0]].push(decodeURIComponent(pair[1]));
    }
  });
  return queryString;
};

// 添加默认参数
function defaultParams() {
  return {
    channelId: getQueryString().channelId || getQueryString().channelFrom || 'ZCQ_JS_SDK',
    success() {},
  };
}

/**
 * 获取Cookie值
 * @param params
 * 如传值就获取传入对应的参数，如果有就返回值。没有就返回空字符串
 * 如果不传值就返回整个cookie转化的对象
 *
 */
const getCookie = (key) => {
  const cookies = document.cookie ? document.cookie.split('; ') : [];
  const rdecode = /(%[0-9A-Z]{2})+/g;
  let i = 0;
  let result = {};

  for (; i < cookies.length; i += 1) {
    const parts = cookies[i].split('=');
    let cookie = parts.slice(1).join('=');
    if (cookie.charAt(0) === '"') {
      cookie = cookie.slice(1, -1);
    }
    const name = parts[0].replace(rdecode, decodeURIComponent);
    cookie = cookie.replace(rdecode, decodeURIComponent);
    if (key === name) {
      result = cookie;
      break;
    }
    if (!key) {
      result[name] = cookie;
    }
  }
  return result;
};

export {
  checkParamsType,
  defaultParams,
  getCookie,
};
